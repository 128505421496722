import React, { useState, useEffect, useRef } from 'react';
import CryptoJS from 'crypto-js';
import { Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '../../../context/data';
import toast from 'react-hot-toast';

function EncryptPDF() {
    const { authURL } = useAuth();
    const { user } = useUser();
    const [pdfFile, setPdfFile] = useState(null);
    const [downloadUrl, setDownloadUrl] = useState('');
    const [password, setPassword] = useState('');
    const [fileName, setFileName] = useState('');
    const [loading, setLoading] = useState(false);
    const downloadLinkRef = useRef(null);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const postFeatureLog = async () => {
        const postData = {
            userID: user.primaryEmailAddress.emailAddress,
            featureName: "Encrypt PDF",
            featureType: "Premium",
            featureCount: 1
        }

        try {
            const response = await fetch(`${authURL}/feature-log`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            console.log('Feature log posted successfully:', result);
        } catch (error) {
            console.error('Error posting feature log:', error);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setPdfFile(file);
            setFileName(file.name);
        } else {
            toast.error('Please select a valid PDF .pdf file.');
        }
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value.replace(/\s/g, ''); // Remove spaces
        setPassword(value);
    };

    const handleEncrypt = () => {
        if (pdfFile && password) {
            setLoading(true);
            const reader = new FileReader();
            reader.onload = () => {
                const pdfData = reader.result;
                const wordArray = CryptoJS.lib.WordArray.create(pdfData);
                const encrypted = CryptoJS.AES.encrypt(wordArray, password).toString();
                const blob = new Blob([encrypted], { type: 'application/octet-stream' });

                const localUrl = URL.createObjectURL(blob);
                setDownloadUrl(localUrl);

                const storageRef = firebase.storage().ref();
                const fileRef = storageRef.child(`${pdfFile.name}.encrypted`);
                fileRef.put(blob).then((snapshot) => {
                    snapshot.ref.getDownloadURL().then((downloadURL) => {
                        POSTEncryptionData(downloadURL);
                        setLoading(false);
                        setTimeout(() => {
                            downloadLinkRef.current.click();
                        }, 1000);
                    });
                }).catch((error) => {
                    console.error('Error uploading file:', error);
                    setLoading(false);
                });
            };
            reader.readAsArrayBuffer(pdfFile);
        } else {
            alert('Please select a PDF file and enter a password.');
        }
    };

    const POSTEncryptionData = async (downloadURL) => {
        const encryptedObj = {
            fileName: fileName,
            fileURL: downloadURL,
            encryptionKey: password,
            email: user.primaryEmailAddress.emailAddress,
        };

        try {
            const response = await fetch(`${authURL}/encrypt-pdf`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(encryptedObj),
            });

            if (response.status === 201) {
                toast.success("File Saved & Encrypted Successfully");
                postFeatureLog();
            } else {
                toast.error("Failed to save & encrypt file. Please try again later.");
            }
        } catch (error) {
            console.error('Error during POSTEncryptionData:', error);
            toast.error("Internal Server Error!");
        }
    };

    return (
        <div className='my-32 h-screen'>
            <div className='md:max-w-4xl xl:max-w-6xl mx-auto flex items-end justify-between'>
                <div>
                    <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
                        Encrypt your PDF
                    </h1>
                    <p className="mt-3 text-gray-600">
                        Encrypt your PDFs files to protect your data.
                    </p>
                </div>
                <div>
                    <Link to='/home/encrypt-pdf/encryption-history' className='text-blue-600 underline'>
                        <span className='flex items-center'>
                            Encryption History <i className="fa-duotone fa-arrow-right ml-1 no-underline"></i>
                        </span>
                    </Link>
                </div>
            </div>

            <div className="mt-7 sm:mt-12 md:max-w-4xl xl:max-w-6xl mx-auto  relative">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                    <div className="h-72 rounded-lg bg-gray-200">
                        <div className="flex items-center justify-center w-full">
                            <label
                                htmlFor="dropzone-file"
                                className="flex flex-col items-center justify-center w-full h-72 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                            >
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg
                                        className="w-8 h-8 mb-4 text-gray-500"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 20 16"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                        />
                                    </svg>
                                    {fileName && (
                                        <p className="my-2">Selected File: <span className='text-blue-700 underline font-bold'>{fileName}</span></p>
                                    )}
                                    <p className="mb-2 text-sm text-gray-500">
                                        <span className="font-semibold">Click to upload</span>
                                    </p>
                                    <p className="text-xs text-gray-500">Any <code className='font-semibold'>.pdf</code> PDF type</p>
                                </div>
                                <input
                                    id="dropzone-file"
                                    type="file"
                                    accept="application/pdf"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>
                    </div>

                    <div className="h-72 rounded-lg border-2 border-gray-300 border-dashed p-8 bg-gray-50">
                        <div>
                            <h1 className='text-xl font-semibold text-start'>ENCRYPTION DETAILS</h1>
                            <p className='text-sm text-gray-500'>Add your password to the associated PDF file for encryption.</p>
                        </div>
                        <div className="h-full flex flex-col justify-center">
                            <label className='text-sm text-gray-500 mb-1'>Encryption Key / Password</label>
                            <input
                                type="password"
                                maxLength={16}
                                className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                placeholder="Enter password..."
                                value={password}
                                onChange={handlePasswordChange}
                            />
                            <button
                                className="w-full mt-4 px-6 py-3 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                                onClick={handleEncrypt}
                                disabled={loading}
                            >
                                {loading ? 'Encrypting...' : 'Encrypt'}
                            </button>
                            {loading && (
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">Please wait...</p>
                                </div>
                            )}
                            {downloadUrl && (
                                <a
                                    ref={downloadLinkRef}
                                    href={downloadUrl}
                                    download={`${fileName}.encrypted`}
                                    style={{ display: 'none' }}
                                >
                                    Download Encrypted File
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EncryptPDF;
