import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '../../../context/data';
import toast from 'react-hot-toast';

function LockPDF() {
    const { authURL } = useAuth();
    const { user } = useUser();
    const [pdfFile, setPdfFile] = useState(null);
    const [lockedPdfBase64, setLockedPdfBase64] = useState('');
    const [password, setPassword] = useState('');
    const [fileName, setFileName] = useState('');
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0); // <-- Add progress state
    const [error, setError] = useState('');
    const [downloadUrl, setDownloadUrl] = useState('');

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setPdfFile(file);
            setFileName(file.name);
        } else {
            toast.error('Please select a valid PDF .pdf file.');
        }
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value.replace(/\s/g, ''); // Remove spaces
        setPassword(value);
    };

    const postFeatureLog = async () => {
        const postData = {
            userID: user.primaryEmailAddress.emailAddress,
            featureName: "PDF Locker",
            featureType: "Premium",
            featureCount: 1
        };

        try {
            const response = await fetch(`${authURL}/feature-log`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            console.log('Feature log posted successfully:', result);
        } catch (error) {
            console.error('Error posting feature log:', error);
        }
    };

    const handleSave = async (lockedPdfBase64) => {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`locked-pdfs/${fileName.split('.')[0]}-locked.pdf`);

        const byteCharacters = atob(lockedPdfBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        try {
            setProgress(75); // Update progress
            const snapshot = await fileRef.put(blob);
            const downloadURL = await snapshot.ref.getDownloadURL();
            setDownloadUrl(downloadURL);
            await postLockedPDFData(downloadURL);
            setProgress(100); // Update progress to 100%
            setTimeout(() => {
                setLoading(false); // Set loading to false after a brief delay
                window.location.href = downloadURL;
            }, 1000);
        } catch (error) {
            console.error('Error saving file:', error);
            setLoading(false);
        }
    };

    const postLockedPDFData = async (downloadURL) => {
        const lockedPDFObj = {
            fileName: fileName,
            fileURL: downloadURL,
            password: password,
            email: user.primaryEmailAddress.emailAddress,
        };

        try {
            const response = await fetch(`${authURL}/post-lock-pdf`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(lockedPDFObj),
            });

            if (response.status === 201) {
                toast.success("File Saved & Encrypted Successfully");
                postFeatureLog();
            } else {
                toast.error("Failed to save & encrypt file. Please try again later.");
            }
        } catch (error) {
            console.error('Error during POSTEncryptionData:', error);
            toast.error("Internal Server Error!");
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!password || !pdfFile) {
            setError('Password and PDF file are required.');
            return;
        }

        setError('');
        setLoading(true);
        setProgress(25); // Update progress

        const reader = new FileReader();
        reader.readAsDataURL(pdfFile);
        reader.onloadend = async () => {
            const pdfBase64 = reader.result.split(',')[1]; // Get the base64 string without the prefix

            try {
                setProgress(50); // Update progress
                const response = await fetch(`${authURL}/lock-pdf`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userPassword: password,
                        pdfBase64: pdfBase64,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setLockedPdfBase64(data.pdf);
                await handleSave(data.pdf); // Await handleSave to ensure it completes before proceeding
            } catch (error) {
                setError('Error encrypting PDF: ' + error.message);
                setLoading(false);
            }
        };
    };

    return (
        <div className='my-32 h-screen'>
            <div className='md:max-w-4xl xl:max-w-6xl mx-auto flex items-end justify-between'>
                <div>
                    <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
                        Lock your PDF
                    </h1>
                    <p className="mt-3 text-gray-600">
                        Lock your PDFs files to protect your data.
                    </p>
                </div>
                <div>
                    <Link to='/home/lock-pdf/pdf-locked-history' className='text-blue-600 underline'>
                        <span className='flex items-center'>
                            Locked PDF History <i className="fa-duotone fa-arrow-right ml-1 no-underline"></i>
                        </span>
                    </Link>
                </div>
            </div>

            <div className="mt-7 sm:mt-12 md:max-w-4xl xl:max-w-6xl mx-auto  relative">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                    <div className="h-78 rounded-lg bg-gray-200">
                        <div className="flex items-center justify-center w-full">
                            <label
                                htmlFor="dropzone-file"
                                className="flex flex-col items-center justify-center w-full h-72 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                            >
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg
                                        className="w-8 h-8 mb-4 text-gray-500"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 20 16"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                        />
                                    </svg>
                                    {fileName && (
                                        <p className="my-2">Selected File: <span className='text-blue-700 underline font-bold'>{fileName}</span></p>
                                    )}
                                    <p className="mb-2 text-sm text-gray-500">
                                        <span className="font-semibold">Click to upload</span>
                                    </p>
                                    <p className="text-xs text-gray-500">Any <code className='font-semibold'>.pdf</code> PDF type</p>
                                    <p className="text-xs text-gray-500">File size Limit: <code className='font-semibold'>50mb</code></p>
                                </div>
                                <input
                                    id="dropzone-file"
                                    type="file"
                                    accept="application/pdf"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>
                    </div>

                    <div className="h-72 rounded-lg border-2 border-gray-300 border-dashed p-8 bg-gray-50">
                        <div>
                            <h1 className='text-xl font-semibold text-start uppercase'>Protection DETAILS</h1>
                            <p className='text-sm text-gray-500'>Add your password to the associated PDF file for protection.</p>
                        </div>
                        <div className="h-full flex flex-col justify-center">
                            <label className='text-sm text-gray-500 mb-1'>Password</label>
                            <input
                                type="password"
                                maxLength={16}
                                className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                placeholder="Enter password..."
                                value={password}
                                onChange={handlePasswordChange}
                            />
                            <button
                                className="w-full mt-4 px-6 py-3 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                {loading ? 'Protecting PDF...' : 'Protect'}
                            </button>
                            {loading && (
                                <div className="my-4">
                                    <p className="text-sm text-gray-500">Protecting: {progress}%</p>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LockPDF;
