import React, { useState, useEffect } from 'react';
import { PDFDocument } from 'pdf-lib';
import toast from 'react-hot-toast';
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '../../../context/data';

function SplitPDF() {
    const { authURL } = useAuth();
    const { user } = useUser();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const [selectedFile, setSelectedFile] = useState(null);
    const [splitFiles, setSplitFiles] = useState([]);
    const [startPage, setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isSplitted, setIsSplitted] = useState(false);
    const [showSplitChunks, setShowSplitChunks] = useState(false);
    const [mergedPdfUrl, setMergedPdfUrl] = useState(null);

    const postFeatureLog = async () => {
        const postData = {
            userID: user.primaryEmailAddress.emailAddress,
            featureName: "Split PDF",
            featureType: "Freemium",
            featureCount: 1
        }

        try {
            const response = await fetch(`${authURL}/feature-log`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            console.log('Feature log posted successfully:', result);
        } catch (error) {
            console.error('Error posting feature log:', error);
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (file && file.type === 'application/pdf' && file.size <= 10 * 1024 * 1024) {
            setSelectedFile(file);
            setSplitFiles([]);
            setMergedPdfUrl(null);
            setIsSplitted(false);
            toast.success('PDF Uploaded Successfully!');
            toast.dismiss();
        } else {
            setSelectedFile(null);
            setSplitFiles([]);
            toast.error('Please upload a valid PDF .pdf file less than 10MB.');
        }
    };

    const handleStartPageChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (!isNaN(value) && value >= 1 && value <= endPage) {
            setStartPage(value);
        } else if (value > endPage) {
            toast.error('Start page cannot be greater than end page.');
        }
    };

    const handleEndPageChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (!isNaN(value) && value >= startPage) {
            setEndPage(value);
        } else if (value < startPage) {
            toast.error('End page cannot be less than start page.');
        }
    };

    const handleSplitPDF = async () => {
        if (!selectedFile) {
            toast.error('Please upload a PDF file first.');
            return;
        }

        if (startPage < 1) {
            toast.error('Start page must be greater than or equal to 1.');
            return;
        }

        setIsLoading(true);
        const fileArrayBuffer = await selectedFile.arrayBuffer();
        const pdfDoc = await PDFDocument.load(fileArrayBuffer);
        const totalPages = pdfDoc.getPageCount();

        if (startPage > totalPages) {
            toast.error(`Start page cannot be greater than total pages (${totalPages}).`);
            setIsLoading(false);
            return;
        }

        if (endPage > totalPages) {
            toast.error(`End page cannot be greater than total pages (${totalPages}).`);
            setIsLoading(false);
            return;
        }

        if (startPage > endPage) {
            toast.error('Start page cannot be greater than end page.');
            setIsLoading(false);
            return;
        }

        const newFiles = [];
        for (let i = startPage - 1; i < endPage; i++) {
            const newPdfDoc = await PDFDocument.create();
            const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [i]);
            newPdfDoc.addPage(copiedPage);

            const pdfBytes = await newPdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);

            newFiles.push({ url, pageNumber: i + 1 });
        }

        setSplitFiles(newFiles);
        setIsSplitted(true);
        setShowSplitChunks(true);
        setIsLoading(false);
        toast.success('PDF Split Successfully!');
        postFeatureLog();

        // Scroll to split and merged section after splitting
        setTimeout(() => {
            document.getElementById('split-section').scrollIntoView({ behavior: 'smooth' });
        }, 500);
    };

    const handleMergePDF = async () => {
        if (splitFiles.length === 0) {
            toast.error('No splitted PDF files to merge.');
            return;
        }

        setIsLoading(true);
        const mergedPdfDoc = await PDFDocument.create();

        for (let file of splitFiles) {
            const fileBytes = await fetch(file.url).then((res) => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(fileBytes);
            const copiedPages = await mergedPdfDoc.copyPages(pdfDoc, pdfDoc.getPageIndices());
            copiedPages.forEach((page) => mergedPdfDoc.addPage(page));
        }

        const mergedPdfBytes = await mergedPdfDoc.save();
        const mergedBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
        const mergedUrl = URL.createObjectURL(mergedBlob);

        setMergedPdfUrl(mergedUrl);
        setIsLoading(false);
    };

    const toggleToMergedPDF = async () => {
        if (!mergedPdfUrl) {
            await handleMergePDF();
        }
        setShowSplitChunks(false);
    };

    const toggleToSplitChunks = () => {
        setShowSplitChunks(true);
    };

    return (
        <div className="relative overflow-hidden my-32">
            <div className="md:max-w-4xl xl:max-w-6xl mx-auto px-4 space-y-8">
                {/* Heading, File Input Form, and Actions */}
                <div className="text-start">
                    <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">Generate Split PDF</h1>
                    <p className="mt-3 text-gray-600">Upload and Split a single PDF file into multiple PDFs to merge them later..</p>
                </div>

                {/* File Input */}
                <div className="mx-auto max-w-7xl relative">
                    <div className="h-full rounded-lg bg-gray-200">
                        <div className="flex items-center justify-center w-full">
                            <label
                                htmlFor="dropzone-file"
                                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                            >
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg
                                        className="w-8 h-8 mb-4 text-gray-500"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 20 16"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                        />
                                    </svg>
                                    {selectedFile && (
                                        <p className="my-2">Selected File: <span className='text-blue-700 underline font-bold'>{selectedFile.name}</span></p>
                                    )}
                                    <p className="mb-2 text-sm text-gray-500">
                                        <span className="font-semibold">Click to upload</span> or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500">Any <code className='font-semibold'>.pdf</code> PDF type</p>
                                </div>
                                <input
                                    id="dropzone-file"
                                    type="file"
                                    accept="application/pdf"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>
                    </div>
                </div>

                {/* Actions */}
                <div className='p-8 border-2 border-gray-300 border-dashed rounded-lg space-y-4 cursor-pointer bg-gray-50'>
                    <div>
                        <h1 className='text-xl uppercase font-semibold'>Actions</h1>
                        <p className='text-sm'>Specify the range of pages to split the PDF from the starting page to the ending page</p>
                    </div>
                    <div className='flex items-end justify-end space-x-8'>
                        <div className='w-full'>
                            <label className='text-sm text-gray-600'>Starting Page Number</label>
                            <input
                                type="number"
                                placeholder="Start Page"
                                value={startPage}
                                onChange={handleStartPageChange}
                                className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400" />
                        </div>
                        <div className='w-full'>
                            <label className='text-sm text-gray-600'>Ending Page Number</label>
                            <input
                                type="number"
                                placeholder="End Page"
                                value={endPage}
                                onChange={handleEndPageChange}
                                className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400" />
                        </div>
                        <button onClick={handleSplitPDF} className="px-6 py-3 w-1/2 rounded-lg bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200">
                            Split PDF
                        </button>
                    </div>
                </div>

                {/* Split Chunks Section */}
                {isSplitted && showSplitChunks && (
                    <div id='split-section' className='p-8 border-2 border-gray-300 border-dashed rounded-lg space-y-4 cursor-pointer bg-gray-50'>
                        <div className='flex items-center justify-between px-2'>
                            <h1 className='text-3xl font-semibold'>Split Chunks</h1>
                            <button onClick={toggleToMergedPDF} className="px-6 py-3 rounded-lg bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200">Show Merged PDF</button>
                        </div>
                        {splitFiles.map((file, index) => (
                            <div key={index} className='mb-4'>
                                <iframe
                                    src={file.url}
                                    title={`Page ${file.pageNumber}`}
                                    className="border border-gray-300 rounded w-full h-[75rem]"
                                />
                                <a href={file.url} download={`page_${file.pageNumber}.pdf`} className="px-4 py-2 bg-blue-500 text-white rounded mt-2 inline-block">Download Page {file.pageNumber}</a>
                            </div>
                        ))}
                    </div>
                )}

                {/* Merged PDF Section */}
                {mergedPdfUrl && !showSplitChunks && (
                    <div className='p-8 border-2 border-gray-300 border-dashed rounded-lg space-y-4 cursor-pointer bg-gray-50'>
                        <div className='flex items-center justify-between px-2'>
                            <h1 className='text-3xl font-semibold'>Merged PDF</h1>
                            <div className='space-x-4'>
                                <a href={mergedPdfUrl} download="merged.pdf" className="px-6 py-3 rounded-lg bg-gradient-to-b from-green-500 to-green-600 text-white focus:ring-2 focus:ring-green-400 hover:shadow-xl transition duration-200">
                                    <i className="fa-duotone fa-solid fa-download fa-lg"></i>
                                </a>
                                <button onClick={toggleToSplitChunks} className="px-6 py-3 rounded-lg bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200">Show Split Chunks</button>
                            </div>
                        </div>
                        <iframe
                            src={mergedPdfUrl}
                            title="Merged PDF"
                            className="border border-gray-300 rounded w-full h-[75rem]"
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default SplitPDF;
