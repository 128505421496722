import React, { useState, useEffect, useRef } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '../../../context/data';
import toast from 'react-hot-toast';

function ViewPDF() {
    const { authURL } = useAuth();
    const { user } = useUser();
    const [selectedPdf, setSelectedPdf] = useState(null);
    const pdfContainerRef = useRef(null);

    const postFeatureLog = async () => {
        const postData = {
            userID: user.primaryEmailAddress.emailAddress,
            featureName: "View PDF",
            featureType: "Freemium",
            featureCount: 1
        }

        try {
            const response = await fetch(`${authURL}/feature-log`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            console.log('Feature log posted successfully:', result);
        } catch (error) {
            console.error('Error posting feature log:', error);
        }
    };
    // Function to handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf' && file.size <= 40 * 1024 * 1024) { // 40MB limit
            setSelectedPdf(file);
            postFeatureLog();
        } else {
            setSelectedPdf(null);
            toast.error('Please select a valid PDF file (up to 40MB).');
        }
    };

    useEffect(() => {
        if (pdfContainerRef.current && selectedPdf) {
            pdfContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [selectedPdf]);

    return (
        <div>
            <div className="relative overflow-hidden h-full mt-32 mb-48">
                <div className="md:max-w-4xl xl:max-w-6xl mx-auto">
                    <div className='text-start'>
                        <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
                            PDF View Tool
                        </h1>
                        <p className="mt-3 text-gray-600">
                            View your PDF file up to a size limit of 40MB
                        </p>
                    </div>

                    <div className="mt-7 sm:mt-12">
                        <div className="h-full rounded-lg bg-gray-200">
                            <div className="flex items-center justify-center w-full">
                                <label
                                    htmlFor="dropzone-file"
                                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                                >
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg
                                            className="w-8 h-8 mb-4 text-gray-500"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 16"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                            />
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500">
                                            <span className="font-semibold">Click to upload</span> or drag and drop
                                        </p>
                                        <p className="text-xs text-gray-500">Any <code className='font-semibold'>.pdf </code> PDF type (up to 40MB)</p>
                                    </div>
                                    <input
                                        id="dropzone-file"
                                        type="file"
                                        accept="application/pdf"
                                        className="hidden"
                                        onChange={handleFileChange}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>

                    {selectedPdf && (
                        <div className='my-24 max-w-7xl mx-auto h-[75rem]' ref={pdfContainerRef}>
                            <h1 className='mb-4 text-xl text-start'>Preview</h1>
                            <div className='w-full border-2 border-gray-300 border-dashed h-[75rem] rounded-lg cursor-pointer bg-gray-50'>
                                {/* Display selected PDF here */}
                                <embed src={URL.createObjectURL(selectedPdf)} type="application/pdf" className="w-full h-full" />
                            </div>
                        </div>
                    )}

                </div>

            </div>
        </div>
    );
}

export default ViewPDF;
