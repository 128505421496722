import React, { useState, useEffect, useRef } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import toast from 'react-hot-toast';
import DemoJSONModal from './Document/Windows/DemoJSONModal';
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '../../../context/data';

function JsonToPDF() {
    const { authURL } = useAuth();
    const { user } = useUser();
    const [showModal, setShowModal] = useState(false);
    const [jsonText, setJsonText] = useState('');
    const [pdfDataUri, setPdfDataUri] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const pdfViewRef = useRef(null);

    const MAX_FILE_SIZE_BYTES = 5 * 1024 * 1024; // 5 MB (adjust as needed)

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Check if the file is a JSON file
            if (file.type !== 'application/json') {
                toast.error('Please upload a valid .json file.');
                return;
            }

            // Check if file size exceeds the limit
            if (file.size > MAX_FILE_SIZE_BYTES) {
                toast.error('File size exceeds the limit of 5MB.');
                return;
            }

            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setJsonText(e.target.result);
            };
            reader.readAsText(file);
        }
    };

    const handleTextAreaChange = (event) => {
        setJsonText(event.target.value);
    };

    // Function to flatten nested JSON objects recursively
    const flattenJson = (obj, prefix = '') => {
        return Object.keys(obj).reduce((acc, key) => {
            const pre = prefix.length ? prefix + '.' : '';
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                Object.assign(acc, flattenJson(obj[key], pre + key));
            } else {
                acc[pre + key] = obj[key];
            }
            return acc;
        }, {});
    };

    const postFeatureLog = async () => {
        const postData = {
            userID: user.primaryEmailAddress.emailAddress,
            featureName: "JSON to PDF",
            featureType: "Freemium",
            featureCount: 1
        };

        try {
            const response = await fetch(`${authURL}/feature-log`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            console.log('Feature log posted successfully:', result);
        } catch (error) {
            console.error('Error posting feature log:', error);
        }
    };

    const convertToPDF = () => {
        try {
            let jsonObj;
            if (!jsonText.trim()) {
                toast.error('Please enter a JSON file OR data.');
                return;
            }
            // Check if the JSON text is already an array, if not, wrap it in an array
            if (jsonText.trim().startsWith('[') && jsonText.trim().endsWith(']')) {
                jsonObj = JSON.parse(jsonText);
            } else {
                jsonObj = JSON.parse(`[${jsonText.trim().replace(/^\[|\]$/g, '')}]`);
            }

            // Flatten nested JSON objects
            const flattenedJson = jsonObj.map(obj => flattenJson(obj));

            // Extract headers from the flattened JSON
            const headers = Array.from(new Set(flattenedJson.flatMap(obj => Object.keys(obj))));

            // Map data to fit the table structure
            const data = flattenedJson.map(obj => headers.map(header => {
                let value = obj[header];
                // Check if value is an image link (you may adjust this condition based on your JSON structure)
                if (typeof value === 'string' && (value.startsWith('http://') || value.startsWith('https://'))) {
                    // Replace with an img element or any other appropriate representation
                    value = { content: '<img src="' + value + '" style="max-width: 50px; max-height: 50px;" />' };
                }
                return value;
            }));

            // Initialize jsPDF
            const doc = new jsPDF();

            doc.autoTable({
                head: [headers],
                body: data,
                startY: 20, // Start Y position of the table. Adjust as needed.
                styles: {
                    fontSize: 8, // Font size
                    cellPadding: 2, // Cell padding (top, left, bottom, right)
                    valign: 'middle', // Vertical alignment (top, middle, bottom)
                    halign: 'center', // Horizontal alignment (left, center, right)
                    overflow: 'linebreak', // Overflow behavior (visible, hidden, linebreak, ellipsize)
                    lineWidth: 0.1, // Line width for borders
                    cellWidth: 'auto', // Cell width 'auto', 'wrap' or a number
                    cellHeight: 'auto', // Cell height 'auto', 'wrap' or a number
                    fontStyle: 'bold', // Font style ('normal', 'bold', 'italic', 'bolditalic')
                },
                headerStyles: {
                    fillColor: [0, 119, 204], // Header fill color (RGB value or array)
                    textColor: [255, 255, 255], // Header text color (RGB value or array)
                    fontStyle: 'bold', // Font style ('normal', 'bold', 'italic', 'bolditalic')
                    halign: 'center', // Horizontal alignment (left, center, right)
                    valign: 'middle', // Vertical alignment (top, middle, bottom)
                    fontSize: 10, // Font size
                    cellPadding: 2, // Cell padding (top, left, bottom, right)
                },
            });

            // Save PDF as data URI and update state
            const pdfUri = doc.output('datauristring');
            setPdfDataUri(pdfUri);
            postFeatureLog();
            toast.success('JSON converted to PDF!');
        } catch (error) {
            toast.error('Invalid JSON');
        }
    };

    const downloadPDF = () => {
        const link = document.createElement('a');
        link.href = pdfDataUri;
        link.download = 'converted.pdf';
        link.click();
        toast.success('Downloaded successfully!');
    };

    useEffect(() => {
        if (pdfDataUri) {
            pdfViewRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [pdfDataUri]);

    return (
        <div className='my-32'>
            <div className="overflow-hidden">
                <div className="md:max-w-4xl xl:max-w-6xl mx-auto">
                    <div className='flex items-end justify-between'>
                        <div>
                            <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
                                Convert JSON to PDFs
                            </h1>
                            <p className="mt-3 text-gray-600">
                                Create, fill, and view PDF converted from a JSON.
                            </p>
                        </div>
                        <button className="px-8 py-2 rounded-full bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200" onClick={() => setShowModal(true)}>Demo JSON</button>
                    </div>
                    <div className="mt-7 sm:mt-12 mx-auto max-w-7xl relative">
                        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                            <div className="h-full rounded-lg bg-gray-200">
                                <div className="flex items-center justify-center w-full">
                                    <label
                                        htmlFor="dropzone-file"
                                        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                                    >
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg
                                                className="w-8 h-8 mb-4 text-gray-500"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 20 16"
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                                />
                                            </svg>
                                            {selectedFile && (
                                                <p className="my-2">Selected File: <span className='text-blue-700 underline font-bold'>{selectedFile.name}</span></p>
                                            )}
                                            <p className="mb-2 text-sm text-gray-500">
                                                <span className="font-semibold">Click to upload</span>
                                            </p>
                                            <p className="text-xs text-gray-500">Any <code className='font-semibold'>.json </code> file type</p>
                                        </div>
                                        <input
                                            id="dropzone-file"
                                            type="file"
                                            accept=".json" // Adjusted to accept .json files
                                            className="hidden"
                                            onChange={handleFileUpload}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="h-[14.5rem]">
                                <h6 className="text-start">Paste JSON here:</h6>
                                <textarea
                                    className="h-full max-h-[14.5rem] w-full p-2 fontCode rounded-lg flex items-center justify-center border-2 border-gray-300 border-dashed bg-gray-50 focus:outline-none"
                                    value={jsonText}
                                    onChange={handleTextAreaChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 mb-12 flex items-center justify-center">
                        <button
                            onClick={convertToPDF}
                            className="px-8 py-2 rounded-full bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                        >
                            Convert
                        </button>
                    </div>
                </div>
            </div>
            {pdfDataUri && (
                <div ref={pdfViewRef} className="h-[75rem] max-w-7xl mx-auto pt-8 mb-24">
                    <div className='flex items-center justify-between mb-4'>
                        <h6 className="text-xl text-start font-semibold">PDF Preview</h6>
                        <button
                            onClick={downloadPDF}
                            className="px-8 py-2 rounded-full bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                        >
                            Download PDF
                        </button>
                    </div>
                    <div className="h-[75rem] w-full fontCode rounded-lg flex items-center justify-center border-2 p-4 border-gray-300 border-dashed bg-gray-50 focus:outline-none">
                        <iframe src={pdfDataUri} className="w-full h-[75rem] py-4" title="PDF Preview"></iframe>
                    </div>
                </div>
            )}
            <DemoJSONModal isVisible={showModal} onClose={() => setShowModal(false)} />
        </div>
    );
}

export default JsonToPDF;
