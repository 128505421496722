import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import invoice from '../../assets/imgs/invoice.gif'
import scanner from '../../assets/imgs/scanner.png'

function Dashboard() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='max-w-7xl m:mx-auto pb-24 space-y-8 mx-6'>

      <div className='max-w-7xl mx-auto mt-32 flex flex-col items-start justify-center'>
        <div class="w-full flex items-center justify-center flex-col gap-5 mb-14">
          <span class='bg-indigo-50 text-indigo-500 text-xs font-medium px-3.5 py-1 rounded-full'>Welcome to</span>
          <h1 className="font-light  capitalize  md:text-6xl">
            <span className="underline font-semibold bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">QuickDoc.app</span>
          </h1>
          <p class="text-lg font-normal text-gray-500 max-w-3xl mx-auto text-center"> In the world of architecture
            or organization, structure provides the backbone for a purposeful and harmonious existence.</p>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">

        <div class="h-44 rounded-lg">
          <Link to='view-pdf' className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue">
            <div className="flex flex-col items-center justify-center p-4">
              <div className='rounded-full'>
                <i className="fa-duotone fa-file-pdf fa-2xl text-blue-600"></i>
              </div>
              <div className='mt-6 text-center'>
                <h1 className='text-lg font-semibold'>PDF Viewer</h1>
                <p className='text-sm'>View PDF and Print it ASAP.</p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg">
          <Link to='split-pdf' className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-red">
            <div className="flex flex-col items-center justify-center p-4">
              <div className='rounded-full'>
                <i className="fa-duotone fa-file-dashed-line fa-2xl text-red-600"></i>
              </div>
              <div className='mt-6 text-center'>
                <h1 className='text-lg font-semibold'>Split PDF</h1>
                <p className='text-sm'>Split a PDF into seperate files.</p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg">
          <Link to='merge-pdf' className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-yellow">
            <div className="flex flex-col items-center justify-center p-4">
              <div className='rounded-full'>
                <i className="fa-duotone fa-merge fa-2xl text-yellow-600"></i>
              </div>
              <div className='mt-6 text-center'>
                <h1 className='text-lg font-semibold'>Merge PDF</h1>
                <p className='text-sm'>Merge multple PDFs from smaller chunks </p>
              </div>
            </div>
          </Link>
        </div>

      </div>

      <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">

        <div class="h-44 rounded-lg">
          <Link to='json-to-pdf' className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-green">
            <div className="flex flex-col items-center justify-center p-4">
              <div className='rounded-full'>
                <i className="fa-duotone fa-file-code fa-2xl text-green-600"></i>
              </div>
              <div className='mt-6 text-center'>
                <h1 className='text-lg font-semibold'>JSON to PDF</h1>
                <p className='text-sm'>Create, Read, Fill Forms</p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg ">
          <Link to='watermark' className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue">
            <div className="flex flex-col items-center justify-center p-4">
              <div className='rounded-full'>
                <i className="fa-duotone fa-file-word fa-2xl text-blue-600"></i>
              </div>
              <div className='mt-6 text-center'>
                <h1 className='text-lg font-semibold'>Add Watermark</h1>
                <p className='text-sm'>Add Watermarks to you PDFs</p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg">
          <Link to='ocr' className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue">
            <div className="flex flex-col items-center justify-center p-4">
              <div className='rounded-full'>
                <i className="fa-duotone fa-scanner-image fa-2xl text-blue-600"></i>
              </div>
              <div className='mt-6 text-center'>
                <h1 className='text-lg font-semibold'>OCR</h1>
                <p className='text-sm'>Extract Text from PDFs</p>
              </div>
            </div>
          </Link>
        </div>


      </div>

      <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">

        <div class="h-44 rounded-lg">
          <Link to='encrypt-pdf' className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue">
            <div className="flex flex-col items-center justify-center p-4">
              <div className='rounded-full'>
                <i className="fa-duotone fa-file-shield fa-2xl text-blue-600"></i>
              </div>
              <div className='mt-6 text-center'>
                <h1 className='text-lg font-semibold'>Encrypt PDF</h1>
                <p className='text-sm px-2'>Encrypt a PDF into meaningless form.</p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg">
          <Link to='decrypt-pdf' className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-yellow">
            <div className="flex flex-col items-center justify-center p-4">
              <div className='rounded-full'>
                <i className="fa-duotone fa-file-circle-check fa-2xl text-yellow-600"></i>
              </div>
              <div className='mt-6 text-center'>
                <h1 className='text-lg font-semibold'>Decrypt PDF</h1>
                <p className='text-sm'>See your Encrypted PDF.</p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg">
          <Link to='lock-pdf' className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue">
            <div className="flex flex-col items-center justify-center p-4">
              <div className='rounded-full'>
                <i className="fa-duotone fa-file-lock fa-2xl text-blue-600"></i>
              </div>
              <div className='mt-6 text-center'>
                <h1 className='text-lg font-semibold'>Lock PDF</h1>
                <p className='text-sm px-2'>Secure a PDF by adding Password over it.</p>
              </div>
            </div>
          </Link>
        </div>

      </div>

      <div class="grid grid-cols-1 gap-4 lg:gap-8">
        <div class="h-64 rounded-lg">
          <Link to='generate-docx' className="group flex flex-co items-center  justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue">
            <div className="flex items-center justify-between p-8 w-full">
              <div>
                <img src={invoice} alt="err" width={260} height={260} />
              </div>

              <div className='text-end space-y-2'>
                <h1 className='text-5xl font-semibold'>Generate Reciepts</h1>
                <p>Generate high quality reciepts and documents with tons of variety of templates for your buisness</p>
                <div className='flex items-end justify-end space-x-4'>
                  <p className='bg-gray-200 py-1 px-3 text-sm rounded'>Invoice</p>
                  <p className='bg-gray-200 py-1 px-3 text-sm rounded'>Sales Reciept</p>
                  <p className='bg-gray-200 py-1 px-3 text-sm rounded'>Purchase Reciept</p>
                  <p className='bg-gray-200 py-1 px-3 text-sm rounded'>Quote</p>
                  <p className='bg-gray-200 py-1 px-3 text-sm rounded'>Memo</p>
                  <p className='bg-gray-200 py-1 px-3 text-sm rounded'>Bill</p>
                </div>
              </div>

            </div>
          </Link>
        </div>
      </div>

    </div>
  )
}

export default Dashboard