import React, { useState } from 'react';
import toast from 'react-hot-toast';
import Bard from '../../../assets/imgs/bard.svg';
import { useAuth } from '../../../context/data';
import { GoogleGenerativeAI } from '@google/generative-ai';
import Tesseract from 'tesseract.js'; // Import Tesseract.js

function JsonToPDF() {
    const { authURL } = useAuth();
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [extractedText, setExtractedText] = useState('');
    const [loading, setLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false); // State to handle processing indication

    // Handle file change
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
        }
    };

    // Extract text from file
    const extractText = async (file) => {
        if (!file) return;

        const fileType = file.type;

        setLoading(true);
        setIsProcessing(true); // Start processing

        try {
            if (fileType.startsWith('image/')) {
                // For images, use Tesseract.js
                const { data: { text } } = await Tesseract.recognize(file, 'eng');
                setExtractedText(text);
            } else if (fileType === 'application/pdf') {
                // Handle PDF extraction if necessary
                toast.error('Select a valid images file types.');
            } else {
                toast.error('Select a valid images file types.');
            }
        } catch (error) {
            console.error('Error extracting text:', error);
            toast.error('Failed to extract text.');
        } finally {
            setLoading(false);
            setIsProcessing(false); // End processing
        }
    };

    // Generate content with the extracted text
    const handleFileUpload = async () => {
        if (!file) return;

        await extractText(file);

        if (extractedText) {
            const genAI = new GoogleGenerativeAI(process.env.REACT_APP_OCR_GENAI);
            const model = genAI.getGenerativeModel({ model: process.env.REACT_APP_OCR_MODEL });

            try {
                const r = await model.generateContent(extractedText);
                setExtractedText(r.response.text());
            } catch (error) {
                console.error('Error generating content:', error);
                toast.error('Failed to generate content.');
            }
        }
    };

    // Copy to clipboard
    const copyToClipboard = () => {
        navigator.clipboard.writeText(extractedText);
        toast.success('Text copied to clipboard!');
    };

    return (
        <div className='mt-32 mb-72 h-full'>
            <div className="overflow-hidden">
                <div className="md:max-w-4xl xl:max-w-6xl mx-auto">
                    <div className='flex items-end justify-between'>
                        <div>
                            <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
                                Extract Text from Images - OCR
                            </h1>
                            <p className="mt-3 text-gray-600">
                                Extract text from a Images file.
                            </p>
                        </div>
                    </div>

                    <div className="mt-7 sm:mt-12 mx-auto max-w-7xl relative mb-4">
                        <div className="flex items-center justify-center space-x-4 h-full w-full">
                            <div className="h-full rounded-lg bg-gray-200 w-full">
                                <div className="flex flex-col items-center justify-center w-full">
                                    <label
                                        htmlFor="dropzone-file"
                                        className="flex items-center justify-center w-full h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                                    >
                                        <div className="flex items-center justify-center pt-5 pb-6">
                                            {!fileName ? (
                                                <>
                                                    <svg
                                                        className="w-8 h-8 text-gray-500"
                                                        aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 20 16"
                                                    >
                                                        <path
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                                        />
                                                    </svg>
                                                    <p className="text-sm text-gray-500 text-center ml-4">
                                                        <span className="font-semibold">Click to upload</span>
                                                        <p className="text-xs text-gray-500">Any <code className='font-semibold'>.jpeg - .jpg - .png </code> file type</p>
                                                    </p>
                                                </>
                                            ) : (
                                                <p className="my-2">Selected File: <span className='text-blue-700 underline font-bold'>{fileName}</span></p>
                                            )}
                                        </div>
                                        <input
                                            id="dropzone-file"
                                            accept=".pdf,.jpg,.png,.jpeg,.webp"
                                            type="file"
                                            className="hidden"
                                            onChange={handleFileChange}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="h-full flex items-center justify-center">
                                <button
                                    className="px-8 py-2 h-24 rounded-lg bg-slate-50 shadow-sm border-2 border-slate-100 focus:ring-2 focus:ring-blue-400 hover:shadow-lg transition duration-200"
                                    onClick={handleFileUpload}
                                    disabled={loading || !file}
                                >
                                    {loading || isProcessing ? 'Processing...' : <><img src={Bard} alt="err" /><span className='text-sm tracking-wider'>Extract</span></>}
                                </button>
                            </div>
                        </div>

                        {/* Conditionally render the extracted text section */}
                        {extractedText && (
                            <div className="mt-12">
                                <div className='flex items-end justify-between mb-3'>
                                    <h6 className="text-start text-xl">Extracted Text:</h6>
                                    <button
                                        className='bg-gradient-to-b from-blue-500 to-blue-600 rounded px-4 py-2 text-white'
                                        onClick={copyToClipboard}
                                    >
                                        <i className="fa-light fa-clone"></i> Copy
                                    </button>
                                </div>
                                <textarea
                                    value={extractedText}
                                    className="w-full p-2 min-h-[99vh] fontCode rounded-lg flex items-center justify-center border-2 border-gray-300 border-dashed bg-gray-50 focus:outline-none"
                                    readOnly
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JsonToPDF;
