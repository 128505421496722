import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Add this line
import toast, { Toaster } from 'react-hot-toast';


function ContactUs() {
    const [Fname, setFName] = useState('');
    const [Lname, setLName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const FnameChange = (e) => setFName(e.target.value);
    const LnameChange = (e) => setLName(e.target.value);
    const emailChange = (e) => setEmail(e.target.value);
    const messageChange = (e) => setMessage(e.target.value);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const name = Fname + ' ' + Lname;
        setIsLoading(true); // Set loading state to true

        if (!Fname || !email) {
            return toast.error("Fill all the details first");
        }

        const serviceId = process.env.REACT_APP_EMAIL_SERVICE;
        const templateId = process.env.REACT_APP_EMAIL_TEMPLATE;
        const publicKey = process.env.REACT_APP_EMAIL_KEY;

        const templateParams = {
            from_name: name,
            from_email: email,
            to_name: 'Awais Khalid - admin',
            message: message,
        };

        try {
            await emailjs.send(serviceId, templateId, templateParams, publicKey);
            toast('Successful - Check your email', {
                icon: '👏',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            });
            setFName('');
            setLName('');
            setEmail('');
            setMessage('');
        } catch (error) {
            console.log(error);
            toast.error('Error submitting your request - try again later');
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };
    return (
        <>
            {/* Contact Us */}
            <div className="max-w-[85rem] px-4 py-10 mt-24 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                <div className="max-w-2xl lg:max-w-5xl mx-auto">


                    <div className='max-w-7xl mx-auto flex flex-col items-start justify-center'>
                        <div class="w-full flex items-center justify-center flex-col gap-5 mb-14">
                            <span class='bg-indigo-50 text-indigo-500 text-xs font-medium px-3.5 py-1 rounded-full'>Contact Us</span>
                            <h1 className="font-light  capitalize  md:text-5xl">
                                Get in <span className="underline font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">Touch</span> with us!
                            </h1>
                            <p class="text-lg font-normal text-gray-500 max-w-3xl mx-auto text-center"> In the world of architecture
                                or organization, structure provides the backbone for a purposeful and harmonious existence.</p>
                        </div>
                    </div>

                    <div className="my-12 grid items-center lg:grid-cols-2 gap-6 lg:gap-16">
                        {/* Card */}
                        <div className="flex flex-col border rounded-xl p-4 sm:p-6 lg:p-8">
                            <h2 className="mb-8 text-xl font-semibold text-gray-800">
                                Fill in the form
                            </h2>
                            <form onSubmit={handleSubmit}>
                                <div className="grid gap-4">
                                    {/* Grid */}
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="hs-firstname-contacts-1" className="sr-only">
                                                First Name
                                            </label>
                                            <input
                                                type="text"
                                                name="hs-firstname-contacts-1"
                                                id="hs-firstname-contacts-1"
                                                onChange={FnameChange}
                                                value={Fname}
                                                className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                                placeholder="First Name"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="hs-lastname-contacts-1" className="sr-only">
                                                Last Name
                                            </label>
                                            <input
                                                type="text"
                                                name="hs-lastname-contacts-1"
                                                onChange={LnameChange}
                                                value={Lname}
                                                id="hs-lastname-contacts-1"
                                                className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                                placeholder="Last Name"
                                            />
                                        </div>
                                    </div>
                                    {/* End Grid */}
                                    <div>
                                        <label htmlFor="hs-email-contacts-1" className="sr-only">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            name="hs-email-contacts-1"
                                            id="hs-email-contacts-1"
                                            onChange={emailChange}
                                            value={email}
                                            autoComplete="email"
                                            className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="hs-about-contacts-1" className="sr-only">
                                            Details
                                        </label>
                                        <textarea
                                            id="hs-about-contacts-1"
                                            name="hs-about-contacts-1"
                                            rows={4}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const regex = /^[a-zA-Z0-9\s]*$/; // Allows only letters, numbers, and spaces
                                                if (regex.test(value)) {
                                                    setMessage(value); // Only set the state if the input is valid
                                                }
                                            }}
                                            value={message}
                                            className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            placeholder="Details"
                                            defaultValue={""}
                                        />
                                    </div>
                                </div>
                                {/* End Grid */}
                                <div className="mt-4 grid">
                                    <button
                                        type="submit"
                                        className="w-full mt-4 px-6 py-3 rounded-full bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                                    >
                                        {isLoading ? 'Submitting...' : 'Submit Request'}
                                    </button>
                                </div>
                                <div className="mt-3 text-center">
                                    <p className="text-sm text-gray-500">
                                        We'll get back to you in 1-2 business days.
                                    </p>
                                </div>
                            </form>
                        </div>
                        {/* End Card */}
                        <div className="divide-y divide-gray-200">
                            {/* Icon Block */}
                            <div className="flex gap-x-7 py-6">
                                <svg
                                    className="flex-shrink-0 size-6 mt-1.5 text-gray-800"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <circle cx={12} cy={12} r={10} />
                                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                                    <path d="M12 17h.01" />
                                </svg>
                                <div className="grow">
                                    <h3 className="font-semibold text-gray-800">Knowledgebase</h3>
                                    <p className="mt-1 text-sm text-gray-500">
                                        We're here to help with any questions or code.
                                    </p>
                                    <a
                                        className="mt-2 inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                                        href="#"
                                    >
                                        Contact support
                                        <svg
                                            className="flex-shrink-0 size-2.5 transition ease-in-out group-hover:translate-x-1"
                                            width={16}
                                            height={16}
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0.975821 6.92249C0.43689 6.92249 -3.50468e-07 7.34222 -3.27835e-07 7.85999C-3.05203e-07 8.37775 0.43689 8.79749 0.975821 8.79749L12.7694 8.79748L7.60447 13.7596C7.22339 14.1257 7.22339 14.7193 7.60447 15.0854C7.98555 15.4515 8.60341 15.4515 8.98449 15.0854L15.6427 8.68862C16.1191 8.23098 16.1191 7.48899 15.6427 7.03134L8.98449 0.634573C8.60341 0.268455 7.98555 0.268456 7.60447 0.634573C7.22339 1.00069 7.22339 1.59428 7.60447 1.9604L12.7694 6.92248L0.975821 6.92249Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            {/* End Icon Block */}
                            {/* Icon Block */}
                            <div className="flex gap-x-7 py-6">
                                <svg
                                    className="flex-shrink-0 size-6 mt-1.5 text-gray-800"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z" />
                                    <path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1" />
                                </svg>
                                <div className="grow">
                                    <h3 className="font-semibold text-gray-800">FAQ</h3>
                                    <p className="mt-1 text-sm text-gray-500">
                                        Search our FAQ for answers to anything you might ask.
                                    </p>
                                    <a
                                        className="mt-2 inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                                        href="#"
                                    >
                                        Visit FAQ
                                        <svg
                                            className="flex-shrink-0 size-2.5 transition ease-in-out group-hover:translate-x-1"
                                            width={16}
                                            height={16}
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0.975821 6.92249C0.43689 6.92249 -3.50468e-07 7.34222 -3.27835e-07 7.85999C-3.05203e-07 8.37775 0.43689 8.79749 0.975821 8.79749L12.7694 8.79748L7.60447 13.7596C7.22339 14.1257 7.22339 14.7193 7.60447 15.0854C7.98555 15.4515 8.60341 15.4515 8.98449 15.0854L15.6427 8.68862C16.1191 8.23098 16.1191 7.48899 15.6427 7.03134L8.98449 0.634573C8.60341 0.268455 7.98555 0.268456 7.60447 0.634573C7.22339 1.00069 7.22339 1.59428 7.60447 1.9604L12.7694 6.92248L0.975821 6.92249Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            {/* End Icon Block */}
                            {/* Icon Block */}
                            <div className=" flex gap-x-7 py-6">
                                <svg
                                    className="flex-shrink-0 size-6 mt-1.5 text-gray-800"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="m7 11 2-2-2-2" />
                                    <path d="M11 13h4" />
                                    <rect width={18} height={18} x={3} y={3} rx={2} ry={2} />
                                </svg>
                                <div className="grow">
                                    <h3 className="font-semibold text-gray-800">Developer APIs</h3>
                                    <p className="mt-1 text-sm text-gray-500">
                                        Check out our development quickstart guide.
                                    </p>
                                    <a
                                        className="mt-2 inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                                        href="#"
                                    >
                                        Contact sales
                                        <svg
                                            className="flex-shrink-0 size-2.5 transition ease-in-out group-hover:translate-x-1"
                                            width={16}
                                            height={16}
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0.975821 6.92249C0.43689 6.92249 -3.50468e-07 7.34222 -3.27835e-07 7.85999C-3.05203e-07 8.37775 0.43689 8.79749 0.975821 8.79749L12.7694 8.79748L7.60447 13.7596C7.22339 14.1257 7.22339 14.7193 7.60447 15.0854C7.98555 15.4515 8.60341 15.4515 8.98449 15.0854L15.6427 8.68862C16.1191 8.23098 16.1191 7.48899 15.6427 7.03134L8.98449 0.634573C8.60341 0.268455 7.98555 0.268456 7.60447 0.634573C7.22339 1.00069 7.22339 1.59428 7.60447 1.9604L12.7694 6.92248L0.975821 6.92249Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            {/* End Icon Block */}
                            {/* Icon Block */}
                            <div className=" flex gap-x-7 py-6">
                                <svg
                                    className="flex-shrink-0 size-6 mt-1.5 text-gray-800"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z" />
                                    <path d="m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10" />
                                </svg>
                                <div className="grow">
                                    <h3 className="font-semibold text-gray-800">
                                        Contact us by email
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-500">
                                        If you wish to write us an email instead please use
                                    </p>
                                    <a
                                        className="mt-2 inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                                        href="#"
                                    >
                                        admin@bzsoftech.com
                                    </a>
                                </div>
                            </div>
                            {/* End Icon Block */}
                        </div>
                    </div>
                </div>
            </div>
            {/* End Contact Us */}
        </>

    )
}

export default ContactUs
