import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

function HeaderContainer({ children }) {
    const location = useLocation();

    const [showNavbar, setShowNavbar] = useState(false);
    useEffect(() => {
        if (location.pathname === '/' || location.pathname === '/pricing' || location.pathname === '/contact-us' || location.pathname === '/about-us') {
            setShowNavbar(true);
        } else {
            setShowNavbar(false);
        }
    }, [location]);

    return (
        <div>
            {showNavbar && children}
        </div>
    )
}

export default HeaderContainer