import React, { useState, useEffect, useRef } from 'react';
import { PDFDocument, rgb, degrees, StandardFonts } from 'pdf-lib-plus-encrypt';
import toast from 'react-hot-toast';
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '../../../context/data';

function WatermarkPDF() {
    const { authURL } = useAuth();
    const { user } = useUser();
    const [pdfFile, setPdfFile] = useState(null);
    const [watermarkText, setWatermarkText] = useState('');
    const [fontSize, setFontSize] = useState(60);
    const [textColor, setTextColor] = useState('#0040FF');
    const [opacity, setOpacity] = useState(0.3);
    const [rotationAngle, setRotationAngle] = useState(0);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState('');

    const iframeRef = useRef(null); // Reference to the iframe element

    const postFeatureLog = async () => {
        const postData = {
            userID: user.primaryEmailAddress.emailAddress,
            featureName: "Watermark",
            featureType: "Premium",
            featureCount: 1
        }

        try {
            const response = await fetch(`${authURL}/feature-log`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            console.log('Feature log posted successfully:', result);
        } catch (error) {
            console.error('Error posting feature log:', error);
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (pdfUrl) {
            iframeRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [pdfUrl]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === 'application/pdf') {
            setPdfFile(file);
            setSelectedFileName(file.name); // Display selected file name
        } else {
            setPdfFile(null);
            setSelectedFileName('');
            toast.error('Please upload a valid PDF file.');
        }
    };


    const handleTextChange = (e) => {
        setWatermarkText(e.target.value);
    };

    const handleFontSizeChange = (e) => {
        setFontSize(parseInt(e.target.value, 10));
    };

    const handleTextColorChange = (e) => {
        setTextColor(e.target.value);
    };

    const handleOpacityChange = (e) => {
        setOpacity(parseFloat(e.target.value));
    };

    const handleRotationChange = (angle) => {
        setRotationAngle(angle);
    };

    const addWatermark = async () => {
        if (!pdfFile || !watermarkText) {
            toast.error('Please upload a PDF file and enter watermark text.');
            return;
        }

        const fileArrayBuffer = await pdfFile.arrayBuffer();
        const pdfDoc = await PDFDocument.load(fileArrayBuffer);

        const pages = pdfDoc.getPages();
        const font = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

        pages.forEach(page => {
            const { width, height } = page.getSize();
            const textWidth = font.widthOfTextAtSize(watermarkText, fontSize);
            const textHeight = fontSize;

            const centerX = width / 2;
            const centerY = height / 2;

            const radiansAngle = radians(rotationAngle);
            const xOffset = Math.cos(radiansAngle) * (textWidth / 2);
            const yOffset = Math.sin(radiansAngle) * (textWidth / 2);

            const x = centerX - xOffset;
            const y = centerY - yOffset;

            page.drawText(watermarkText, {
                x,
                y,
                size: fontSize,
                font,
                color: rgb(
                    parseInt(textColor.slice(1, 3), 16) / 255,
                    parseInt(textColor.slice(3, 5), 16) / 255,
                    parseInt(textColor.slice(5, 7), 16) / 255
                ),
                opacity,
                rotate: degrees(rotationAngle),
            });
        });

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
        postFeatureLog();
    };

    const radians = (degrees) => {
        return degrees * Math.PI / 180;
    };

    return (
        <div className="md:max-w-4xl xl:max-w-6xl mx-auto px-4 py-12">
            <div className='text-start'>
                <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">Watermark</h1>
                <p className="mt-3 text-gray-600">Add Watermark to your PDFs</p>
            </div>

            <div className="mt-7 sm:mt-12 mx-auto max-w-7xl relative">
                <div className="h-[14.5rem] rounded-lg bg-gray-200">
                    <div className="flex items-center justify-center w-full">
                        <label
                            htmlFor="dropzone-file"
                            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                        >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg
                                    className="w-8 h-8 mb-4 text-gray-500"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 16"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                    />
                                </svg>
                                {selectedFileName && (
                                    <p className="my-2">Selected File: <span className='text-blue-700 underline font-bold'>{selectedFileName}</span></p>
                                )}
                                <p className="mb-2 text-sm text-gray-500">
                                    <span className="font-semibold">Click to upload</span> or drag and drop
                                </p>
                                <p className="text-xs text-gray-500">Any <code className='font-semibold'>.pdf </code> PDF type</p>
                            </div>
                            <input
                                id="dropzone-file"
                                type="file"
                                accept="application/pdf"
                                className="hidden"
                                onChange={handleFileChange}
                            />
                        </label>
                    </div>
                </div>
            </div>
            <div className='mt-12 mx-auto max-w-7xl'>
                <h1 className="text-2xl font-bold text-gray-800 text-start mb-4">Actions</h1>
                <div className=" mx-auto max-w-7xl relative h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 p-8">

                    <div className='flex space-x-4 text-start'>
                        <div className="relative mb-4">
                            <label className="leading-7 text-sm text-gray-600">Watermark Text</label>
                            <input
                                type="text"
                                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                value={watermarkText}
                                onChange={handleTextChange}
                                maxLength={16}
                            />
                            <p className='text-sm text-red-500'>(max 16 characters)</p>
                        </div>
                        <div className="relative mb-4">
                            <label className="leading-7 text-sm text-gray-600">Watermark Size</label>
                            <input
                                type="number"
                                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                value={fontSize}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value, 10);

                                    // Allow only integer values between 10 and 60
                                    if (Number.isInteger(value) && value >= 10 && value <= 60) {
                                        handleFontSizeChange(e);
                                    } else if (value < 10) {
                                        handleFontSizeChange({ target: { value: 10 } });
                                    } else if (value > 60) {
                                        handleFontSizeChange({ target: { value: 60 } });
                                    }
                                }}
                                onKeyDown={(e) => {
                                    const invalidChars = ["-", "+", ".", "e"];

                                    // Prevent the entry of invalid characters
                                    if (invalidChars.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                min="10"
                                max="60"
                            />
                            <p className='text-sm text-red-500'>(max size 60)</p>
                        </div>
                        <div className="relative mb-4">
                            <label className="leading-7 text-sm text-gray-600">Watermark Color</label>
                            <input
                                type="color"
                                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                value={textColor}
                                onChange={handleTextColorChange}
                            />
                        </div>
                        <div className="relative mb-4">
                            <label className="leading-7 text-sm text-gray-600">Opacity</label>
                            <input
                                type="range"
                                min="0"
                                max="1"
                                step="0.1"
                                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                value={opacity}
                                onChange={handleOpacityChange}
                            />
                        </div>
                    </div>

                    <h1 className="text font-semibold text-gray-800 text-start my-4">Watermark Rotation</h1>

                    <div className='flex items-start space-x-6'>
                        <div className='flex items-center'>
                            <input
                                type="radio"
                                id="rotate0"
                                name="rotation"
                                value="0"
                                checked={rotationAngle === 0}
                                onChange={() => handleRotationChange(0)}
                                className="mr-2"
                            />
                            <label htmlFor="rotate0">0°</label>
                        </div>
                        <div className='flex items-center'>
                            <input
                                type="radio"
                                id="rotate45"
                                name="rotation"
                                value="45"
                                checked={rotationAngle === 45}
                                onChange={() => handleRotationChange(45)}
                                className="mr-2"
                            />
                            <label htmlFor="rotate45">45°</label>
                        </div>
                        <div className='flex items-center'>
                            <input
                                type="radio"
                                id="rotate90"
                                name="rotation"
                                value="-45"
                                checked={rotationAngle === -45}
                                onChange={() => handleRotationChange(-45)}
                                className="mr-2"
                            />
                            <label htmlFor="rotate90">-45°</label>
                        </div>
                    </div>

                    <div className='h-full mt-4'>
                        <button
                            className="px-6 py-3 rounded-lg bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                            onClick={addWatermark}
                        >
                            Add Watermark
                        </button>
                    </div>

                </div>

            </div>

            {pdfUrl && (
                <div style={{ marginTop: '40px' }}>
                    <iframe
                        ref={iframeRef} // Attach the ref to the iframe
                        src={pdfUrl}
                        width="100%"
                        height='1200px'
                        style={{ border: 'none' }}
                        title="Watermarked PDF"
                    />
                </div>
            )}
        </div>
    );
}

export default WatermarkPDF;
