import React, { useState } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

//* importing Components
import Header from './components/Header/Header';
import HeaderContainer from './components/Header/HeaderContainer';
import ErrorPage from './ErrorPage';
import ContactUs from './Pages/ContactUs';
import About from './Pages/About';
import Pricing from './Pages/Pricing';
import LandingPage from './Pages/LandingPage';
import Loader from './components/Loader/Loader'
import Footer from './components/Footer/Footer';
import FooterContainer from './components/Footer/FooterContainer'
import ScrollToTop from './components/ScrollToTop';

// ! ADMINISTRATOR Routes
import AdminProtectedRoutes from './Pages/Admin/AdminProtectedRoute';
import AdminLayout from './components/Layouts/Admin/AdminLayout';
import AdminLogin from './components/Auth/AdminLogin';
import AdminRegister from './components/Auth/AdminRegister';
import Dashboard from './Pages/Admin/Dashboard';
import FreemiumUser from './Pages/Admin/FreemiumUser';
import PremiumUser from './Pages/Admin/PremiumUser';
import PricePlans from './Pages/Admin/PricePlans';
import ProfitSales from './Pages/Admin/ProfitSales';
import FeatureLogs from './Pages/Admin/FeatureLogs'

// ! Client Routes
import ClientProtectedRoute from './Pages/Client/ClientProtectedRoute';
import Home from './Pages/Client/Dashboard';
import ClientLayout from './components/Layouts/Client/ClientLayout';
import UserLogin from './components/Auth/UserLogin';
import UserSignUp from './components/Auth/UserSignUp';
import PremiumProtectedRoute from './Pages/Client/PremiumProtectedRoute'
import ViewPDF from './Pages/Client/tools/ViewPDF';
import JsonToPDF from './Pages/Client/tools/JsonToPDF'
import MergePDF from './Pages/Client/tools/MergePDF'
import SplitPDF from './Pages/Client/tools/SplitPDF'
import WatermarkPDF from './Pages/Client/tools/WatermarkPDF'
import EncryptPDF from './Pages/Client/tools/EncryptPDF';
import LockPDF from './Pages/Client/tools/LockPDF';
import DecryptPDF from './Pages/Client/tools/DecryptPDF'
import EncryptionHistory from './Pages/Client/tools/EncryptionHistory';
import PDFHistory from './Pages/Client/tools/LockedPDFHistory';
import GenerateDocument from './Pages/Client/tools/GenerateDocument';
import Invoice from './Pages/Client/tools/Document/Invoice/Invoice';
import InvoiceTemplate from './Pages/Client/tools/Document/Invoice/InvoiceTemplate';
import Purchases from './Pages/Client/Purchases';
import ComingSoon from './Pages/ComingSoon';
import OCR from './Pages/Client/tools/OCR';
import InvoiceHistory from './Pages/Client/tools/Document/Invoice/InvoiceHistory';
import EditInvoice from './Pages/Client/tools/Document/Invoice/EditInvoice';
import SalesReceipt from './Pages/Client/tools/Document/SalesReciept/SalesReceipt';
import ClientsData from './Pages/Client/tools/Document/ClientsData';
import SalesTemplate from './Pages/Client/tools/Document/SalesReciept/SalesTemplate';
import SalesHistory from './Pages/Client/tools/Document/SalesReciept/SalesHistory';
import EditSales from './Pages/Client/tools/Document/SalesReciept/EditSales';
import EditBill from './Pages/Client/tools/Document/BillsReciept/EditBill';
import BillsHistory from './Pages/Client/tools/Document/BillsReciept/BillsHistory';
import BillsReceipt from './Pages/Client/tools/Document/BillsReciept/BillsReceipt';
import BillsTemplate from './Pages/Client/tools/Document/BillsReciept/BillsTemplate';
import Quotes from './Pages/Client/tools/Document/Quotes/Quotes';
import QuotesTemplate from './Pages/Client/tools/Document/Quotes/QuotesTemplate';
import Memo from './Pages/Client/tools/Document/Memo/Memo';
import MemoTemplate from './Pages/Client/tools/Document/Memo/MemoTemplate';
import EditMemo from './Pages/Client/tools/Document/Memo/EditMemo';
import MemoHistory from './Pages/Client/tools/Document/Memo/MemoHistory';
import Purchase from './Pages/Client/tools/Document/Purchase/Purchase';
import PurchaseTemplate from './Pages/Client/tools/Document/Purchase/PurchaseTemplate';
import EditPurchase from './Pages/Client/tools/Document/Purchase/EditPurchase';
import PurchaseHistory from './Pages/Client/tools/Document/Purchase/PurchaseHistory';
import QuotesHistory from './Pages/Client/tools/Document/Quotes/QuotesHistory';
import EditQuotes from './Pages/Client/tools/Document/Quotes/EditQuotes';

function App() {
  const [loading, setLoading] = useState(false); // State for managing loading indicator
  setTimeout(() => setLoading(false), 3000);

  return (
    <>
      <ScrollToTop></ScrollToTop>
      {loading && <Loader />}

      <HeaderContainer>
        <Header />
      </HeaderContainer>

      <Routes>

        <Route path="/" element={<LandingPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/pricing" element={<Pricing />} />

        {/* USER Login Routes */}
        <Route path="/login" element={<UserLogin />} />
        <Route path="/register" element={<UserSignUp />} />

        {/* Client Protected Routes=========================================================================== */}
        <Route path='/home' element={<ClientProtectedRoute Component={ClientLayout} />}>
          <Route index element={<Home />} />
          <Route path="purchases" element={<Purchases />} />
          <Route path="view-pdf" element={<ViewPDF />} />
          <Route path="json-to-pdf" element={<JsonToPDF />} />
          <Route path="split-pdf" element={<SplitPDF />} />
          <Route path="merge-pdf" element={<MergePDF />} />
          <Route path="watermark" element={<WatermarkPDF />} />
          <Route path="ocr" element={<OCR />} />

          <Route path="encrypt-pdf" element={<PremiumProtectedRoute Component={EncryptPDF} />} />
          <Route path="/home/encrypt-pdf/encryption-history" element={<PremiumProtectedRoute Component={EncryptionHistory} />} />
          <Route path="decrypt-pdf" element={<PremiumProtectedRoute Component={DecryptPDF} />} />
          <Route path="lock-pdf" element={<PremiumProtectedRoute Component={LockPDF} />} />
          <Route path="/home/lock-pdf/pdf-locked-history" element={<PremiumProtectedRoute Component={PDFHistory} />} />

          <Route path="generate-docx" element={<PremiumProtectedRoute Component={GenerateDocument} />} />
          <Route path="/home/generate-docx/manage-clients" element={<PremiumProtectedRoute Component={ClientsData} />} />

          <Route path="/home/generate-docx/invoice-data" element={<PremiumProtectedRoute Component={Invoice} />} />
          <Route path="/home/generate-docx/invoice-data/invoice-history" element={<PremiumProtectedRoute Component={InvoiceHistory} />} />
          <Route path="/home/generate-docx/invoice-data/update-invoice" element={<PremiumProtectedRoute Component={EditInvoice} />} />
          <Route path="/home/generate-docx/invoice-data/template" element={<PremiumProtectedRoute Component={InvoiceTemplate} />} />

          <Route path="/home/generate-docx/sales-reciept-form" element={<PremiumProtectedRoute Component={SalesReceipt} />} />
          <Route path="/home/generate-docx/sales-reciept-form/sales-template" element={<PremiumProtectedRoute Component={SalesTemplate} />} />
          <Route path="/home/generate-docx/sales-history" element={<PremiumProtectedRoute Component={SalesHistory} />} />
          <Route path="/home/generate-docx/update-sales" element={<PremiumProtectedRoute Component={EditSales} />} />

          <Route path="/home/generate-docx/bills-reciept-form" element={<PremiumProtectedRoute Component={BillsReceipt} />} />
          <Route path="/home/generate-docx/bills-reciept-form/bills-template" element={<PremiumProtectedRoute Component={BillsTemplate} />} />
          <Route path="/home/generate-docx/bills-history" element={<PremiumProtectedRoute Component={BillsHistory} />} />
          <Route path="/home/generate-docx/update-bills" element={<PremiumProtectedRoute Component={EditBill} />} />

          <Route path="/home/generate-docx/quotes-form" element={<PremiumProtectedRoute Component={Quotes} />} />
          <Route path="/home/generate-docx/quotes-form/quotes-template" element={<PremiumProtectedRoute Component={QuotesTemplate} />} />
          <Route path="/home/generate-docx/quotes-history" element={<PremiumProtectedRoute Component={QuotesHistory} />} />
          <Route path="/home/generate-docx/quotes-form/update-quotes" element={<PremiumProtectedRoute Component={EditQuotes} />} />

          <Route path="/home/generate-docx/memo-form" element={<PremiumProtectedRoute Component={Memo} />} />
          <Route path="/home/generate-docx/memo-history" element={<PremiumProtectedRoute Component={MemoHistory} />} />
          <Route path="/home/generate-docx/memo-form/memo-template" element={<PremiumProtectedRoute Component={MemoTemplate} />} />
          <Route path="/home/generate-docx/memo-form/update-memo" element={<PremiumProtectedRoute Component={EditMemo} />} />

          <Route path='/home/generate-docx/purchase-form' element={<PremiumProtectedRoute Component={Purchase} />} />
          <Route path="/home/generate-docx/purchase-form/purchase-template" element={<PremiumProtectedRoute Component={PurchaseTemplate} />} />
          <Route path="/home/generate-docx/purchase-form/update-purchase" element={<PremiumProtectedRoute Component={EditPurchase} />} />
          <Route path="/home/generate-docx/purchase-history" element={<PremiumProtectedRoute Component={PurchaseHistory} />} />
        </Route>

        {/* ADMINISTRATOR Routes============================================================================= */}
        <Route path="/admin-register" element={<AdminRegister />} />
        <Route path="/admin-login" element={<AdminLogin />} />

        <Route path='/admin' element={<AdminProtectedRoutes Component={AdminLayout} />}>
          <Route index element={<Dashboard />} />
          <Route path="freemium-users" element={<FreemiumUser />} />
          <Route path="premium-users" element={<PremiumUser />} />
          <Route path="plans" element={<PricePlans />} />
          <Route path="sales" element={<ProfitSales />} />
          <Route path="feature-logs" element={<FeatureLogs />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
      </Routes>

      <FooterContainer>
        <Footer />
      </FooterContainer>

      <Toaster />
    </>
  );
}

export default App;
