import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

function FooterContainer({ children }) {
    const location = useLocation();

    const [showFooter, setshowFooter] = useState(false);
    useEffect(() => {
        if (location.pathname === '/admin' || location.pathname === '/admin' || location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/home/generate-docx/invoice-data/invoice-history' || location.pathname === 'freemium-users' || location.pathname === 'premium-users' || location.pathname === 'plans' || location.pathname === 'sales') {
            setshowFooter(false);
        } else {
            setshowFooter(true);
        }
    }, [location]);
    return (
        <div>
            {showFooter && children}
        </div>
    )
}

export default FooterContainer