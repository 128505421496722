import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

function Pricing() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])
    return (
        <div className='relative'>
            <section class="z-10 py-12 bg-white sm:py-16 lg:py-32">
                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">

                    <div className='max-w-7xl mx-auto flex flex-col items-start justify-center'>
                        <div class="w-full flex items-center justify-center flex-col gap-5 mb-14">
                            <span class='bg-indigo-50 text-indigo-500 text-xs font-medium px-3.5 py-1 rounded-full'>Pricing and Packages Plans</span>
                            <h1 className="font-light  capitalize  md:text-5xl">
                                Get Affordable <span className="underline font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">Pricing</span> of our features!
                            </h1>
                            <p class="text-lg font-normal text-gray-500 max-w-3xl mx-auto text-center"> In the world of architecture
                                or organization, structure provides the backbone for a purposeful and harmonious existence.</p>
                        </div>

                    </div>

                    <div class="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                        <div class="space-y-4 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-8 lg:space-y-0 lg:items-center">

                            <div class="flex flex-col mx-auto max-w-lg text-gray-900 rounded-2xl bg-gray-50 p-6 xl:py-9 xl:px-12 transition-all duration-500 hover:bg-gray-100">
                                <h3 class="font-manrope text-2xl font-bold mb-3">Basic</h3>
                                <div class="flex items-center mb-6">
                                    <span class="font-manrope mr-2 text-6xl font-semibold">$0</span>
                                    <span class="text-xl text-gray-500 ">/ month</span>
                                </div>
                                <ul class="mb-12 space-y-6 text-left text-lg text-gray-500">
                                    <li class="flex items-center space-x-4">
                                        <svg
                                            class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                stroke="currentColor"
                                                stroke-width="1.6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <span>PDF Viewer</span>
                                    </li>
                                    <li class="flex items-center space-x-4">
                                        <svg
                                            class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                stroke="currentColor"
                                                stroke-width="1.6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <span>Split PDF </span>
                                    </li>
                                    <li class="flex items-center space-x-4">
                                        <svg
                                            class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                stroke="currentColor"
                                                stroke-width="1.6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <span>Merge PDF </span>
                                    </li>
                                    <li class="flex items-center space-x-4">
                                        <svg
                                            class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                stroke="currentColor"
                                                stroke-width="1.6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <span>Add WaterMark to PDF</span>
                                    </li>
                                </ul>
                                <Link to='/home'
                                    class="py-2.5 px-5 bg-gradient-to-b from-blue-700 via-blue-700 to-blue-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto hover:bg-indigo-700">
                                    Get Started
                                </Link>
                            </div>

                            <div class="flex flex-col mx-auto max-w-lg text-gray-900 rounded-2xl bg-indigo-50 transition-all duration-500 hover:bg-indigo-100 ">
                                <div class="uppercase bg-gradient-to-b from-blue-700 via-blue-700 to-blue-600 rounded-t-2xl p-3 text-center text-white">
                                    MOST POPULAR
                                </div>
                                <div class="p-6 xl:py-9 xl:px-12">
                                    <h3 class="font-manrope text-2xl font-bold mb-3">Advanced</h3>
                                    <div class="flex items-center mb-6">
                                        <span class="font-manrope mr-2 text-6xl font-semibold text-indigo-600">
                                            $30
                                        </span>
                                        <span class="text-xl text-gray-500 ">/ month</span>
                                    </div>

                                    <ul class="mb-12 space-y-6 text-left text-lg ">
                                        <li class="flex items-center space-x-4">
                                            <svg
                                                class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                                viewBox="0 0 30 30"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.6"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            <span>JSON to PDF</span>
                                        </li>
                                        <li class="flex items-center space-x-4">
                                            <svg
                                                class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                                viewBox="0 0 30 30"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.6"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            <span>Encrypt PDF</span>
                                        </li>
                                        <li class="flex items-center space-x-4">
                                            <svg
                                                class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                                viewBox="0 0 30 30"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.6"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            <span>Decrypt PDF</span>
                                        </li>
                                        <li class="flex items-center space-x-4">
                                            <svg
                                                class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                                viewBox="0 0 30 30"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.6"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            <span>AI integrated OCR</span>
                                        </li>
                                        <li class="flex items-center space-x-4">
                                            <svg
                                                class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                                viewBox="0 0 30 30"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.6"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            <span>3 Day Encryption PDF Storage</span>
                                        </li>
                                        <li class="flex items-center space-x-4">
                                            <svg
                                                class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                                viewBox="0 0 30 30"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.6"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            <span>Document Generator</span>
                                        </li>
                                    </ul>
                                    <Link to='/contact-us'
                                        class="py-2.5 px-5 bg-gradient-to-b from-blue-700 via-blue-700 to-blue-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit block mx-auto hover:bg-indigo-700"
                                    >
                                        Purchase Plan
                                    </Link>
                                </div>
                            </div>

                            <div class="flex flex-col mx-auto max-w-lg text-gray-900 rounded-2xl bg-gray-50 p-6 xl:py-9 xl:px-12 transition-all duration-500 hover:bg-gray-100">
                                <h3 class="font-manrope text-2xl font-bold mb-3">Ultimate</h3>
                                <div class="flex items-center mb-6">
                                    <span class="font-manrope mr-2 text-6xl font-semibold">
                                        $200
                                    </span>
                                    <span class="text-xl text-gray-500 ">/ year</span>
                                </div>

                                <ul class="mb-12 space-y-6 text-left text-lg text-gray-500">
                                    <li class="flex items-center space-x-4">
                                        <svg
                                            class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                stroke="currentColor"
                                                stroke-width="1.6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <span>JSON to PDF</span>
                                    </li>
                                    <li class="flex items-center space-x-4">
                                        <svg
                                            class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                stroke="currentColor"
                                                stroke-width="1.6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <span>Encrypt PDF</span>
                                    </li>
                                    <li class="flex items-center space-x-4">
                                        <svg
                                            class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                stroke="currentColor"
                                                stroke-width="1.6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <span>Decrypt PDF</span>
                                    </li>
                                    <li class="flex items-center space-x-4">
                                        <svg
                                            class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                stroke="currentColor"
                                                stroke-width="1.6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <span>AI Integrated OCR</span>
                                    </li>
                                    <li class="flex items-center space-x-4">
                                        <svg
                                            class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                stroke="currentColor"
                                                stroke-width="1.6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <span>1 Month Encrypted File Storage</span>
                                    </li>
                                    <li class="flex items-center space-x-4">
                                        <svg
                                            class="flex-shrink-0 w-6 h-6 text-indigo-600"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                                                stroke="currentColor"
                                                stroke-width="1.6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <span>Document Generator</span>
                                    </li>
                                </ul>
                                <Link to='/contact-us'
                                    class="py-2.5 px-5 bg-gradient-to-b from-blue-700 via-blue-700 to-blue-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto hover:bg-indigo-700"
                                >
                                    Purchase Plan
                                </Link>
                            </div>

                        </div>
                    </div>

                    <div class="flex flex-col items-center justify-center mt-12 mx-auto max-w-5xl text-gray-900 rounded-2xl bg-blue-50 p-6 xl:py-9 xl:px-12 transition-all duration-500 hover:bg-gray-100">
                        <h3 class="font-manrope text-2xl font-bold mb-3">For Making Custom Package</h3>
                        <div class="flex items-center mb-6">
                            <span class="font-manrope mr-2 text-6xl font-semibold">
                                Custom Featured Package
                            </span>
                        </div>

                        <Link to='/contact-us'
                            class="py-2.5 px-5 bg-gradient-to-b from-blue-700 via-blue-700 to-blue-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto hover:bg-indigo-700"
                        >
                            Contact Sales Team
                        </Link>
                    </div>


                </div>
            </section>
        </div>
    )
}

export default Pricing