import React, { useState, useEffect, useRef } from 'react';
import { PDFDocument } from 'pdf-lib';
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '../../../context/data';
import toast from 'react-hot-toast';

function MergePDF() {
  const { authURL } = useAuth();
  const { user } = useUser();
  const [pdfFiles, setPdfFiles] = useState([]);
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const fileListRef = useRef(null); // Create a ref for the file list div

  useEffect(() => {
    // Smooth scroll to the file list div when files are selected
    if (fileListRef.current) {
      fileListRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [pdfFiles]);

  const onFileChange = (event) => {
    const files = Array.from(event.target.files);
    const pdfFilesOnly = files.filter(file => file.type === 'application/pdf');

    if (pdfFilesOnly.length !== files.length) {
      toast.error('Please upload only PDF files.');
    }

    if (pdfFilesOnly.length > 0) {
      setPdfFiles((prevFiles) => [...prevFiles, ...pdfFilesOnly]);
      setShowButtons(true); // Show buttons when files are uploaded
    }
  };


  const onDragStart = (index) => {
    setDraggingIndex(index);
  };

  const postFeatureLog = async () => {
    const postData = {
      userID: user.primaryEmailAddress.emailAddress,
      featureName: "Merge PDF",
      featureType: "Freemium",
      featureCount: 1
    }

    try {
      const response = await fetch(`${authURL}/feature-log`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log('Feature log posted successfully:', result);
    } catch (error) {
      console.error('Error posting feature log:', error);
    }
  };

  const onDragOver = (index) => {
    if (draggingIndex === index) return;
    const updatedFiles = [...pdfFiles];
    const draggedFile = updatedFiles.splice(draggingIndex, 1)[0];
    updatedFiles.splice(index, 0, draggedFile);
    setDraggingIndex(index);
    setPdfFiles(updatedFiles);
  };

  const mergeAndDownloadPDFs = async () => {
    const mergedPdf = await PDFDocument.create();

    for (const file of pdfFiles) {
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await PDFDocument.load(arrayBuffer);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const mergedPdfFile = await mergedPdf.save();
    const blob = new Blob([mergedPdfFile], { type: 'application/pdf' });
    postFeatureLog();
    const url = URL.createObjectURL(blob);

    // Create a link and trigger a download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'merged.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderPDFPreview = (file) => {
    const url = URL.createObjectURL(file);
    return (
      <div className="mt-2">
        <object
          data={url}
          type="application/pdf"
          width="100%"
          height="850px"
        >
          <p>Preview not available</p>
        </object>
      </div>
    );
  };

  return (
    <div className="relative overflow-hidden mt-32 mb-44">
      <div className="md:max-w-4xl xl:max-w-6xl mx-auto px-4 space-y-8">
        {/* Heading, File Input Form, and Actions */}
        <div className="text-start">
          <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">Generate Merged PDF</h1>
          <p className="mt-3 text-gray-600">Merge multiple PDF files into a single merged PDF file.</p>
        </div>

        {/* File Input */}
        <div className="md:max-w-4xl xl:max-w-6xl mx-auto relative">
          <div className="h-full rounded-lg bg-gray-200">
            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="font-semibold">Click to upload</span> or drag and drop
                  </p>
                  <p className="text-xs text-gray-500">Any <code className='font-semibold'>.pdf </code> PDF type</p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  accept="application/pdf"
                  className="hidden"
                  multiple
                  onChange={onFileChange}
                />
              </label>
            </div>
          </div>
        </div>

        {/* PDF Files List */}
        {showButtons && pdfFiles.length > 0 && (
          <div ref={fileListRef} className="mx-auto max-w-7xl relative mt-8 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-100 p-8">

            <div className='flex items-center justify-between mb-8'>
              <div>
                <h1 className='text-3xl uppercase font-semibold'>Uploaded Files</h1>
                <p className='text-m'>Drag from File Name to change the sequence of the PDFs Files</p>
              </div>
              <div>
                {showButtons && (
                  <div className="mx-auto max-w-7xl relative flex justify-center mt-8">
                    <button
                      onClick={mergeAndDownloadPDFs}
                      className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                    >
                      Merge and Download PDF
                    </button>
                  </div>
                )}
              </div>

            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {pdfFiles.map((file, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow p-4"
                  draggable
                  onDragStart={() => onDragStart(index)}
                  onDragOver={() => onDragOver(index)}
                >
                  <span className='text-blue-700'>{file.name}</span>
                  {renderPDFPreview(file)}
                  <button
                    onClick={() => setPdfFiles(pdfFiles.filter((_, i) => i !== index))}
                    className="text-red-500 hover:text-red-700 mt-2"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MergePDF;
