import React, { useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';

function AdminLayout() {
    document.title = "Admin - Dashboard";

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const logout = () => {
        localStorage.removeItem('token');
        window.location.href = '/';
    }
    return (
        <>
            <aside class="fixed flex flex-col top-0 left-0 z-50 w-64 h-screen py-8 bg-white shadow-sm border-r-2 border-slate-100">

                <div className='mx-auto flex flex-col items-center justify-center'>
                    <a href="#">
                        <svg id="logo-35" width="55" height="39" viewBox="0 0 50 39" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16.4992 2H37.5808L22.0816 24.9729H1L16.4992 2Z" class="ccompli1" fill="#007AFF"></path> <path d="M17.4224 27.102L11.4192 36H33.5008L49 13.0271H32.7024L23.2064 27.102H17.4224Z" class="ccustom" fill="#312ECB"></path> </svg>
                    </a>
                    <h1 className='text-lg font-semibold mt-2'>QuickDoc.app</h1>
                </div>

                <nav className='flex flex-col justify-between h-full mt-12'>

                    <div>
                        <Link to='/admin' class="flex items-center px-8 py-4 text-gray-700 focus:bg-slate-100">
                            <i class="fa-regular fa-home fa-lg"></i>

                            <span class="mx-4 font-medium">Dashboard</span>
                        </Link>

                        <Link to='freemium-users' class="flex items-center px-8 py-4 text-gray-700 focus:bg-slate-100">
                            <i class="fa-regular fa-user-unlock fa-lg"></i>

                            <span class="mx-4 font-medium">Freemium Users</span>
                        </Link>

                        <Link to='premium-users' class="flex items-center px-8 py-4 text-gray-700 focus:bg-slate-100">
                            <i class="fa-regular fa-user-lock fa-lg"></i>

                            <span class="mx-4 font-medium">Preemium Users</span>
                        </Link>

                        <Link to='feature-logs' class="flex items-center px-8 py-4 text-gray-700 focus:bg-slate-100">
                            <i class="fa-regular fa-chart-line-up fa-lg"></i>

                            <span class="mx-4 font-medium">Feature Logs</span>
                        </Link>

                        <Link to='plans' class="flex items-center px-8 py-4 text-gray-700 focus:bg-slate-100">
                            <i class="fa-regular fa-coins fa-lg"></i>

                            <span class="mx-4 font-medium">Price Plans</span>
                        </Link>

                        <Link to='sales' class="flex items-center px-8 py-4 text-gray-700 focus:bg-slate-100">
                            <i class="fa-regular fa-hands-holding-dollar fa-lg"></i>

                            <span class="mx-4 font-medium">Profit / Sales</span>
                        </Link>
                    </div>

                    <div>
                        <button onClick={logout} class="flex items-center px-8 py-4 text-gray-700 focus:bg-slate-100">
                            <i class="fa-regular fa-door-open fa-lg"></i>

                            <span class="mx-4 font-medium">Log out</span>
                        </button>
                    </div>

                </nav>

            </aside>

            <header className="fixed top-0 left-0 right-0 z-40 h-16 sm:ml-64 flex items-center px-8 bg-white shadow-sm border-b-1 border-slate-200">
                <div className='flex items-center justify-between w-full'>
                    <h1 className='text-slate-950'>Welcome, Administrator</h1>
                    <button onClick={logout} className="activeBtn px-4 py-1 rounded text-sm bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200">Log Out</button>
                </div>
            </header>

            <div className='sm:ml-64 p-8 mt-16 bg-slate-100'>
                <Outlet />
            </div>
        </>
    );
}

export default AdminLayout;
